/* Beleren Bold */
@font-face {
  font-family: "Beleren Bold";
  src:
    url(/static/media/Beleren2016-Bold.c2b0f65c.woff) format("woff"),
    url(/static/media/Beleren2016-Bold.06d6a788.ttf) format("truetype"),
    url(/static/media/Beleren2016-Bold.6f4f54c7.svg#Beleren2016-Bold) format("svg");
  font-style: normal;
  font-weight: bold;
  text-rendering: optimizeLegibility;
}

/* Beleren SmallCaps Bold */
@font-face {
  font-family: "Beleren SmallCaps Bold";
  src:
    url(/static/media/Beleren2016SmallCaps-Bold.1002a456.woff) format("woff"),
    url(/static/media/Beleren2016SmallCaps-Bold.427549e9.ttf) format("truetype"),
    url(/static/media/Beleren2016SmallCaps-Bold.f9ba650a.svg#Beleren2016SmallCaps-Bold) format("svg");
  font-style: normal;
  font-weight: bold;
  text-rendering: optimizeLegibility;
}

/* Beleren SmallCaps BoldItalic */
@font-face {
  font-family: "Beleren SmallCaps BoldItalic";
  src:
    url(/static/media/Beleren2016SmallCaps-BoldItalic.fa927570.woff) format("woff"),
    url(/static/media/Beleren2016SmallCaps-BoldItalic.5645117e.ttf) format("truetype"),
    url(/static/media/Beleren2016SmallCaps-BoldItalic.df29f400.svg#Beleren2016SmallCaps-BoldItalic) format("svg");
  font-style: italic;
  font-weight: bold;
  text-rendering: optimizeLegibility;
}

